import { useMemo } from 'react';

const Title = ({ as, text }) => {
  const textArray = useMemo(() => {
    return text.split('/');
  }, [text]);
  if (as === 'h1')
    return (
      <h1 className={'lg:text-title text-center text-[12vw] uppercase leading-[0.9]'}>
        <span>{textArray[0]}</span>
        <br />
        <span className={'font-normal'}>{textArray[1]}</span>
        <br />
        <span>{textArray[2]}</span>
      </h1>
    );
  if (as === 'h2') {
    return (
      <h2 className={'lg:text-title text-center text-[12vw] uppercase leading-[0.9]'}>
        <span>{textArray[0]}</span>
        <br />
        <span className={'font-normal'}>{textArray[1]}</span>
        <br />
        <span>{textArray[2]}</span>
      </h2>
    );
  }
};

export default Title;
