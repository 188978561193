import { ReactComponent as Logo } from '../logo.svg';
import Link from './Link';

const Footer = () => {
  return (
    <footer className={'mx-auto mb-10 flex flex-col items-center gap-3 lg:mb-12 lg:w-[740px]'}>
      <Logo />
      <nav className={'flex gap-4'}>
        <Link href={'https://sunlovetour.com/'} className={'text-base decoration-2'}>
          STORE
        </Link>
        <Link href={'https://pf.kakao.com/_RmJxfxj'} className={'text-base decoration-2'}>
          KAKAO
        </Link>
        <Link href={'https://www.instagram.com/sunlovetour/'} className={'text-base decoration-2'}>
          INSTAGRAM
        </Link>
        <Link href={'https://www.facebook.com/sunlove.tour'} className={'text-base decoration-2'}>
          FACEBOOK
        </Link>
      </nav>
      <span className={'text-base'}>© 2024 Sunlove. All right reserved.</span>
    </footer>
  );
};

export default Footer;
