import classNames from 'classnames';

const Link = ({ href, children, className }) => {
  return (
    <a target={'_blank'} className={classNames('underline decoration-dotted decoration-3', className)} href={href}>
      {children}
    </a>
  );
};

export default Link;
