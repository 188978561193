import React, { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { useScrollDirection } from 'react-use-scroll-direction';
import { useMediaQuery } from 'react-responsive';
import { browserName } from 'react-device-detect';
import Link from './components/Link';
import Title from './components/Title';
import Article from './components/Article';
import Paragraph from './components/Paragraph';
import Box from './components/Box';
import Footer from './components/Footer';
import Teaser from './teaser.jpg';

const useCheckPC = (minWidth = 1024) => {
  const isPC = useMediaQuery({
    query: `(min-width: ${minWidth}px)`,
  });

  return { isPC };
};

const publicUrl = process.env.PUBLIC_URL;

const App = () => {
  const { isPC } = useCheckPC();

  const [isPlayable, setIsPlayable] = useState(false);
  const [direction, setDirection] = useState('DOWN');

  const playerRef = useRef(null);
  const lottieRef = useRef(null);
  const lottie = useMemo(() => lottieRef.current, [lottieRef.current]);

  const root = useMemo(() => document.querySelector('#root'), []);
  const { scrollDirection } = useScrollDirection(root);

  useEffect(() => {
    if (browserName === 'Chrome') {
      document.querySelector('footer').style.paddingBottom = '400px';
    }
  }, [browserName]);

  useEffect(() => {
    if (scrollDirection !== null) {
      setDirection(scrollDirection);
    } else if (root.scrollTop < 0) {
      setDirection('DOWN');
    }
  }, [scrollDirection]);

  useEffect(() => {
    setIsPlayable(true);
  }, [direction]);

  const isScrollable = useMemo(() => root.scrollTop > 0, [root.scrollTop]);

  useEffect(() => {}, [isScrollable]);

  useEffect(() => {
    if (lottie && isScrollable && isPlayable) {
      const segments = direction === 'DOWN' ? [0, 25] : [25, 40];
      lottie.playSegments(segments, true);
    }
  }, [lottie, isPlayable, direction, isScrollable]);

  useEffect(() => {
    if (!isPC) {
      const windowHeight = window.innerHeight;
      document.body.style.height = `${windowHeight}px`;
      root.style.height = `${windowHeight}px`;
      document.querySelector('#lottie').style.width = `${(windowHeight / 16) * 9}px`;
    }
  }, [window.innerHeight, isPC, root]);

  return (
    <>
      <Player
        ref={playerRef}
        lottieRef={(ref) => {
          if (ref) lottieRef.current = ref;
        }}
        src={isPC ? `/lottie/sunlove_lottie_desktop_v3.json` : `/lottie/sunlove_lottie_mobile_v3.json`}
        keepLastFrame={true}
      />
      <main>
        <Article>
          <Title as={'h1'} text={'Sunlove/SS24 Preview/Exhibition'} />
          <Paragraph className={'text-center'}>
            2024.2.2(FRI) – 2.4(SUN)
            <br />
            12PM – 8PM
            <br />
            서울 성동구 성수이로7가길 3, 3층
          </Paragraph>
          {/*<div className={'w-fit overflow-hidden rounded-2xl'}>
            <img className={'w-fit px-[8%]'} src={Teaser} />
          </div>*/}
          <Paragraph>
            We cordially invite you to the SUNLOVE Spring Summer 2024 Preview Exhibition. At this exhibition, you will
            have the opportunity to experience 144 items specifically curated for the spring and summer seasons in
            person. Exclusive giveaways and special events, available only during the exhibition hours, await you. Be
            the first to experience the new season of SUNLOVE from February 2nd to 4th.
          </Paragraph>
          <Paragraph>
            Our Spring Summer 2024 collection reinterprets the late 90s golfer style through modern lenses, utilizing
            high-performance and high-quality premium fabrics. The collection includes a range of products designed for
            various outdoor activities, from waterproof jackets crafted with Schoeller®'s premium 3L fabric to base
            layer t-shirts featuring Polartec®'s Delta fabric. In addition, the main color palette showcases Cyan,
            Yellow, and Pink, offering a vibrant and diverse range of colors. Embracing bold patterns and iconic
            graphics, SUNLOVE continues to encapsulate its distinctive sporty flair. Furthermore, the expanded women's
            lineup provides female customers with even more diverse choices.
          </Paragraph>
          <Paragraph lang={'kr'}>
            SUNLOVE Spring Summer 2024 프리뷰 전시에 여러분을 초대합니다. 이번 전시에서는 봄과 여름을 위한 144개의
            아이템을 오프라인에서 직접 경험해 보실 수 있습니다. 전시 시간 동안에만 증정되는 익스클루시브 사은품과
            이벤트가 준비되어 있습니다. 새로운 시즌의 SUNLOVE를 2월 2일부터 4일까지, 가장 먼저 만나보세요.
          </Paragraph>
          <Paragraph lang={'kr'}>
            Spring Summer 2024 시즌은 90년대 후반의 골퍼 스타일을 고기능, 고품질의 프리미엄 원단을 통해 현대적으로
            해석했습니다. Schoeller®사의 프리미엄 3L 원단을 사용한 방수 재킷부터 Polartec®사의 Delta 원단을 사용한
            베이스레이어 티셔츠까지, 다양한 아웃도어 활동을 위한 제품군으로 구성되어 있습니다. 또한 Cyan, Yellow, Pink
            세 가지를 메인으로 다채로운 컬러 팔레트를 선보입니다. 볼드한 패턴과 아이코닉한 그래픽을 적용하여 지난 시즌에
            이어 SUNLOVE만의 스포티함을 담았습니다. 이 외에도, 확장된 여성복 라인업을 통해 여성 고객들에게 더욱 폭넓은
            선택지를 제안합니다.
          </Paragraph>
        </Article>
        <Article>
          <Title as={'h2'} text={'Where/to/Exhibit'} />
          <Paragraph>
            <Link
              href={
                'https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%8F%99%EA%B5%AC%20%EC%84%B1%EC%88%98%EC%9D%B4%EB%A1%9C7%EA%B0%80%EA%B8%B8%203/address/14143668.681373,4514736.2099074,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%8F%99%EA%B5%AC%20%EC%84%B1%EC%88%98%EC%9D%B4%EB%A1%9C7%EA%B0%80%EA%B8%B8%203,new?c=19.00,0,0,0,dh&isCorrectAnswer=true'
              }
            >
              3F, 3, Seongsui-ro 7ga-gil, Seongdong-gu, Seoul
            </Link>
            <br />
            <br />
            <Link
              href={
                'https://map.naver.com/p/search/%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%8F%99%EA%B5%AC%20%EC%84%B1%EC%88%98%EC%9D%B4%EB%A1%9C7%EA%B0%80%EA%B8%B8%203/address/14143668.681373,4514736.2099074,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EC%84%B1%EB%8F%99%EA%B5%AC%20%EC%84%B1%EC%88%98%EC%9D%B4%EB%A1%9C7%EA%B0%80%EA%B8%B8%203,new?c=19.00,0,0,0,dh&isCorrectAnswer=true'
              }
            >
              서울 성동구 성수이로7가길 3, 3층
            </Link>
          </Paragraph>
        </Article>
        <Article>
          <Title as={'h2'} text={'Gift/for/Visitor'} />
          <Box>
            <ol className={'list-inside list-decimal'}>
              <li>Cookie and beverages</li>
              <li>Lookbook with Sticker</li>
              <li>Daybreak Pin</li>
              <li>FORE! Tee (10 people per day)</li>
            </ol>
          </Box>
          <Paragraph>
            All gifts will be given upon following{' '}
            <Link href={'https://www.instagram.com/sunlovetour/'}>@sunlovetour</Link>. The gift giveaway event may end
            early if the prepared quantity is exhausted.
          </Paragraph>
          <Paragraph lang={'kr'}>
            모든 기프트는 <Link href={'https://www.instagram.com/sunlovetour/'}>@sunlovetour</Link> 팔로우 시
            증정됩니다. 기프트 증정 이벤트는 준비된 수량 소진 시 조기종료될 수 있습니다.
          </Paragraph>
        </Article>
        <Article>
          <Title as={'h2'} text={'Event/for/Visitor'} />
          <Paragraph>
            When you upload a verification post or story on your personal Instagram, you have a chance to win ₩200,000
            in SUNLOVE official online store credit through a random draw. Winners will be announced individually via DM
            on 02.07(Wed) after the exhibition ends. If there is no response within 7 days, the win will be canceled.
            Winners will be excluded if the account is private or if they unfollow the SUNLOVE account at the time of
            the announcement.
          </Paragraph>
          <Paragraph lang={'kr'}>
            개인 인스타그램에 방문 인증 게시물 또는 스토리 업로드 시, 추첨을 통해 총 5명에게 SUNLOVE 공식 온라인 스토어
            적립금 20만원을 증정합니다. 당첨자는 전시 종료 후, 2/7(수) 개별 DM을 통하여 발표될 예정입니다. 7일 내 회신이
            없을 경우에는 당첨이 취소되며, 비공개 계정이거나 발표 시점 SUNLOVE 계정 팔로우 취소 시에는 당첨에서
            제외됩니다.
          </Paragraph>
        </Article>
      </main>
      <Footer />
    </>
  );
};

export default App;
